import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UserAccountManagementService} from '../user-account-management/user-account-management.service';
import {Observable} from 'rxjs';
import {AUTHORIZED_FOR_USER_ROLES} from './authorization-keys';
import {UserRole} from '../models/user-role.enum';

@Injectable()
export class AuthorizationGuard  {

	constructor(private userManagementService: UserAccountManagementService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean |
		UrlTree> | boolean | UrlTree {
		const authorizedUserRoles: Array<UserRole> = route.data[AUTHORIZED_FOR_USER_ROLES] || [];
		if (this.userManagementService.isUserAuthorized(authorizedUserRoles)) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}
}
